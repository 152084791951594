export default [
  {
    path: "/user",
    name: "user",
    component: () => import("@/modules/user/User.vue"),
    children: [
      {
        path: "list",
        name: "list-users",
        component: () => import("@/modules/user/pages/UserList.vue"),
        meta: { permissions: ["users-show"] }
      },
      {
        path: "add",
        name: "add-user",
        component: () => import("@/modules/user/pages/UserAdd.vue"),
        meta: { permissions: ["users-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-user",
        component: () => import("@/modules/user/pages/UserEdit.vue"),
        meta: { permissions: ["users-update"] }
      }
    ]
  }
];
