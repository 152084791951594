import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "../services/store";
import router from "../../router";
import JwtService from "@/core/services/jwt.service";
import { API_URL } from "@/core/config/config.js";
import { CHECK_TOKEN } from "@/core/services/store/auth.module";
import { PURGE_AUTH } from "@/core/services/store/auth.module";
import i18nService from "./i18n.service";

/**
 * Check if access token has expired before request and refresh it
 */
const createAxiosResponseInterceptor = () => {
  const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Reject promise if usual error
      if (
        error.response.status !== 401 ||
        error.response.config.url === "refresh"
      ) {
        return Promise.reject(error);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      axios.interceptors.response.eject(interceptor);

      return store
        .dispatch(CHECK_TOKEN)
        .then((data) => {
          error.response.config.headers["Authorization"] =
            "Bearer " + data.data.access_token;
          return axios(error.response.config);
        })
        .catch((error) => {
          store.commit(PURGE_AUTH);
          router.push({ name: "login" });
          // JwtService.destroyTokens();
          // router.push({ name: "login" });
          return Promise.reject(error);
        })
        .finally(createAxiosResponseInterceptor);
    }
  );
};

/**
 * Set the default HTTP request headers
 */
const setHeader = () => {
  axios.interceptors.request.use((config) => {
    let token = JwtService.getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    let language = i18nService.getActiveLanguage();
    if (language) {
      config.headers["Accept-Language"] = `${language}`;
    } else {
      config.headers["Accept-Language"] = `en`;
    }

    return config;
  });
};

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    setHeader();
    createAxiosResponseInterceptor();
  },

  query(resource, params) {
    return Vue.axios.get(resource, {
      params: params,
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    let url = "";
    if (slug != "") {
      url = `${resource}/${slug}`;
    } else {
      url = `${resource}`;
    }
    return Vue.axios.get(url);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params = {}, options = {}) {
    return Vue.axios.post(`${resource}`, params, options);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, slug) {
    let url = resource;

    if (slug) {
      url += "/" + slug;
    }

    return Vue.axios.delete(url);
  },
};

export default ApiService;
