import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_FUELS_TYPES = "fetchFuelsTypes";
export const FETCH_FUEL_TYPE = "fetchFuelType";
export const SAVE_FUEL_TYPE = "saveFuelType";
export const UPDATE_FUEL_TYPE = "updateFuelType";
export const DELETE_FUEL_TYPE = "deleteFuelType";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingFuelType";
export const SET_FUELS_TYPES = "setFuelsTypes";

const state = {
  errors: [],
  fuel_type: [],
  isLoadingFuelType: false
};

const getters = {
  isLoadingFuelType() {
    return state.isLoadingFuelType;
  },
  getFuelType() {
    return state.fuel_type
  }
};

const actions = {
  [FETCH_FUELS_TYPES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fuels-types" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_FUEL_TYPE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fuels-types", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_FUEL_TYPE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("fuels-types", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_FUEL_TYPE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("fuels-types", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_FUEL_TYPE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("fuels-types", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingFuelType = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingFuelType = value;
  },
  [SET_FUELS_TYPES](state, data) {
    state.isLoadingFuelsType = false;
    state.fuel_type = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
