export default [
  {
    path: "/staff-vehicle",
    name: "staff-vehicle",
    component: () => import("@/modules/staff-vehicle/StaffVehicle.vue"),
    children: [
      {
        path: "list",
        name: "list-staff-vehicle",
        component: () => import("@/modules/staff-vehicle/pages/StaffVehicleList.vue"),
        meta: { permissions: ["staff-vehicle-show"] }
      },
      {
        path: "add",
        name: "add-staff-vehicle",
        component: () => import("@/modules/staff-vehicle/pages/StaffVehicleAdd.vue"),
        meta: { permissions: ["staff-vehicle-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-staff-vehicle",
        component: () => import("@/modules/staff-vehicle/pages/StaffVehicleEdit.vue"),
        meta: { permissions: ["staff-vehicle-update"] }
      }
    ]
  }
];
