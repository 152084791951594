export default [
  {
    path: "/service-warehouse-items",
    name: "service-warehouse-items",
    component: () =>
      import("@/modules/service-warehouse-items/ServiceWarehouseItems.vue"),
    children: [
      {
        path: "list",
        name: "list-service-warehouse-items",
        component: () =>
          import(
            "@/modules/service-warehouse-items/pages/ServiceWarehouseItemsList.vue"
          ),
        meta: { permissions: ["service-show"] }
      },
      {
        path: "add",
        name: "add-service-warehouse-items",
        component: () =>
          import("@/modules/service-warehouse-items/pages/ServiceWarehouseItemsAdd.vue"),
        meta: { permissions: ["service-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-service-warehouse-items",
        component: () =>
          import(
            "@/modules/service-warehouse-items/pages/ServiceWarehouseItemsEdit.vue"
          ),
        meta: { permissions: ["service-update"] }
      }
    ]
  }
];
