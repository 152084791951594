import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_SUBCONTRACTORS_TYPES = "fetchSubcontractorsTypes";
export const FETCH_SUBCONTRACTOR_TYPE = "fetchSubcontractorType";
export const SAVE_SUBCONTRACTOR_TYPE = "saveSubcontractorType";
export const UPDATE_SUBCONTRACTOR_TYPE = "updateSubcontractorType";
export const DELETE_SUBCONTRACTOR_TYPE = "deleteSubcontractorType";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingSubcontractorType";
export const SET_SUBCONTRACTORS_TYPES = "setSubcontractorsTypes";

const state = {
  errors: [],
  subcontractor_type: [],
  isLoadingSubcontractorType: false
};

const getters = {
  isLoadingSubcontractorType() {
    return state.isLoadingSubcontractorType;
  },
  getSubcontractorType() {
    return state.subcontractor_type
  }
};

const actions = {
  [FETCH_SUBCONTRACTORS_TYPES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("subcontractors-types" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_SUBCONTRACTOR_TYPE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("subcontractors-types", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_SUBCONTRACTOR_TYPE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("subcontractors-types", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_SUBCONTRACTOR_TYPE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("subcontractors-types", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_SUBCONTRACTOR_TYPE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("subcontractors-types", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingSubcontractorType = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingSubcontractorType = value;
  },
  [SET_SUBCONTRACTORS_TYPES](state, data) {
    state.isLoadingSubcontractorType = false;
    state.subcontractor_type = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
