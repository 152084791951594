import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_FUEL_EXPENSES = "fetchFuelExpenses";
export const FETCH_FUEL_EXPENSE = "fetchFuelExpense";
export const SAVE_FUEL_EXPENSE = "saveFuelExpense";
export const UPDATE_FUEL_EXPENSE = "updateFuelExpense";
export const DELETE_FUEL_EXPENSE = "deleteFuelExpense";
export const GENERATE_FUEL_EXPENSE_PDF_URL = "generateFuelExpensePdfUrl";
export const GENERATE_EXPORT_FUEL_EXPENSE_ROWS_URL =
  "generateExportFuelExpenseRowsUrl";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingFuelExpense";
export const SET_FUEL_EXPENSE = "setFuelExpense";

const state = {
  errors: [],
  fuel_expense: [],
  isLoadingFuelExpense: false
};

const getters = {
  isLoadingFuelExpense() {
    return state.isLoadingFuelExpense;
  },
  getFuelExpense() {
    return state.fuel_expense;
  }
};

const actions = {
  [FETCH_FUEL_EXPENSES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fuel-expenses" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_FUEL_EXPENSE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fuel-expenses", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_FUEL_EXPENSE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("fuel-expenses", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_FUEL_EXPENSE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("fuel-expenses", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_FUEL_EXPENSE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("fuel-expenses", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_FUEL_EXPENSE_PDF_URL](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`fuel-expenses/${id}/generate-fuel-expense-pdf-url`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_EXPORT_FUEL_EXPENSE_ROWS_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`/generate-export-fuel-expense-rows-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingFuelExpense = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingFuelExpense = value;
  },
  [SET_FUEL_EXPENSE](state, data) {
    state.isLoadingFuelExpense = false;
    state.fuel_expense = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
