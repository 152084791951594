import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_ASSIGNMENTS = "fetchAssignments";
export const FETCH_ASSIGNMENT = "fetchAssignment";
export const SAVE_ASSIGNMENT = "saveAssignment";
export const UPDATE_ASSIGNMENT = "updateAssignment";
export const DELETE_ASSIGNMENT = "deleteAssignment";
export const CHECK_ARE_AVAILABLE = "checkAreAvailable";
export const FETCH_LOG = "fetchLog";
export const GENERATE_EXCEL_URL = "generateExcelUrl";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingAssignment";
export const SET_ASSIGNMENTS = "setAssignments";

const state = {
  errors: [],
  assignment: [],
  isLoadingAssignment: false
};

const getters = {
  isLoadingAssignment() {
    return state.isLoadingAssignment;
  },
  getAssignment() {
    return state.assignment;
  }
};

const actions = {
  [FETCH_ASSIGNMENTS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("assignments" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ASSIGNMENT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("assignments", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_ASSIGNMENT](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("assignments", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_ASSIGNMENT](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("assignments", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_ASSIGNMENT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("assignments", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [CHECK_ARE_AVAILABLE](context, payload) {
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("assignments-check-are-available", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [FETCH_LOG](context, payload) {
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("assignments-get-log", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GENERATE_EXCEL_URL](context, apiParams) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`assignments-generate-excel-url` + apiParams)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingAssignment = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingAssignment = value;
  },
  [SET_ASSIGNMENTS](state, data) {
    state.isLoadingAssignments = false;
    state.assignment = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
