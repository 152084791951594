import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_ASSIGNORS = "fetchAssignors";
export const FETCH_ASSIGNOR  = "fetchAssignor";
export const SAVE_ASSIGNOR   = "saveAssignor";
export const UPDATE_ASSIGNOR = "updateAssignor";
export const DELETE_ASSIGNOR = "deleteAssignor";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingAssignors";
export const SET_ASSIGNORS = "setAssignors";

const state = {
  assignors_errors: [],
  assignors: [],
  isLoadingAssignor: false
};

const getters = {
  isLoadingAssignor() {
    return state.isLoadingAssignor;
  },
  getAssignors() {
    return state.assignors;
  }
};

const actions = {
  [FETCH_ASSIGNORS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("assignors" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ASSIGNOR](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("assignors", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_ASSIGNOR](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("assignors", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_ASSIGNOR](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("assignors", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_ASSIGNOR](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("assignors", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingAssignors = false;
    if (Array.isArray(errors)) {
      state.assignors_errors = errors;
    } else {
      state.assignors_errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingAssignors = value;
  },
  [SET_ASSIGNORS](state, data) {
    state.isLoadingAssignors = false;
    state.assignors = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
