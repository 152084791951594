import ApiService from "@/core/services/api.service";

//Actions
export const FETCH_SELECTS_BASIC = "fetchSelectsBasic";

//Mutations
export const SET_LOADING = "setLoadingSharedBase";

const state = {
  isLoadingSharedBase: false
};

const getters = {
  isLoadingSharedBase() {
    return state.isLoadingSharedBase;
  }
};

const actions = {
  [FETCH_SELECTS_BASIC](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("selects", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_LOADING](state, value) {
    state.isLoadingSharedBase = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
