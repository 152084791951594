export default [
  {
    path: "/role",
    name: "role",
    component: () => import("@/modules/role/Role.vue"),
    children: [
      {
        path: "list",
        name: "list-roles",
        component: () => import("@/modules/role/pages/RoleList.vue"),
        meta: { permissions: ["roles-show"] }
      },
      {
        path: "add",
        name: "add-role",
        component: () => import("@/modules/role/pages/RoleAdd.vue"),
        meta: { permissions: ["roles-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-role",
        component: () => import("@/modules/role/pages/RoleEdit.vue"),
        meta: { permissions: ["roles-update"] }
      }
    ]
  }
];
