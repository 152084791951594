export default [
  {
    path: "/subcontractors-types",
    name: "subcontractors-types",
    component: () => import("@/modules/subcontractors-types/SubcontractorsTypes.vue"),
    children: [
      {
        path: "list",
        name: "list-subcontractor-type",
        component: () => import("@/modules/subcontractors-types/pages/SubcontractorTypeList.vue"),
        meta: { permissions: ["subcontractors-types-show"] }
      },
      {
        path: "add",
        name: "add-subcontractor-type",
        component: () => import("@/modules/subcontractors-types/pages/SubcontractorTypeAdd.vue"),
        meta: { permissions: ["subcontractors-types-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-subcontractor-type",
        component: () => import("@/modules/subcontractors-types/pages/SubcontractorTypeEdit.vue"),
        meta: { permissions: ["subcontractors-types-update"] }
      }
    ]
  }
];
