// Register a global custom directive called `v-permission`
import Vue from "vue";

/** Check if user has permission or has role. If doesn't have permission DOM element is removed and replaced by html comment
 * @arg {Array | optional} roles
 */

Vue.directive("permission", (el, binding, vnode) => {
  let roles, userRole, permissions, userPermissions;
  let hide = false;
  if (vnode.context.$store && vnode.context.$store.getters.currentUser) {
    if (binding.arg == "roles") {
      roles = binding.value;
      userRole = vnode.context.$store.getters.currentUser.role;
    } else {
      permissions = binding.value;
      userPermissions = vnode.context.$store.getters.currentUser.permissions;
    }
    if (roles && roles.length) {
      if (!roles.includes(userRole)) {
        hide = true;
      }
    } else if (
      permissions &&
      permissions.length &&
      userPermissions &&
      !permissions.some(v => userPermissions.includes(v))
    ) {
      hide = true;
    }
  }
  if (hide == true) {
    // replace HTMLElement with comment node
    const comment = document.createComment(" ");
    Object.defineProperty(comment, "setAttribute", {
      value: () => undefined
    });
    vnode.elm = comment;
    vnode.text = " ";
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;
    vnode.data.directives = undefined;

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment;
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el);
    }
  }
});
