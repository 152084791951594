export default [
  {
    path: "/services",
    name: "services",
    component: () => import("@/modules/services/Services.vue"),
    children: [
      {
        path: "list",
        name: "list-services",
        component: () => import("@/modules/services/pages/ServicesList.vue"),
        meta: { permissions: ["services-show"] },
      },
      {
        path: "add",
        name: "add-services",
        component: () => import("@/modules/services/pages/ServicesAdd.vue"),
        meta: { permissions: ["services-create"] },
        props: true,
      },
      {
        path: "edit/:id",
        name: "edit-services",
        component: () => import("@/modules/services/pages/ServicesEdit.vue"),
        meta: { permissions: ["services-update"] },
      },
    ],
  },
];
