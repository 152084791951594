export default [
  {
    path: "/fuels-types",
    name: "fuels-types",
    component: () => import("@/modules/fuels-types/FuelsTypes.vue"),
    children: [
      {
        path: "list",
        name: "list-fuel-type",
        component: () => import("@/modules/fuels-types/pages/FuelTypeList.vue"),
        meta: { permissions: ["fuels-types-show"] }
      },
      {
        path: "add",
        name: "add-fuel-type",
        component: () => import("@/modules/fuels-types/pages/FuelTypeAdd.vue"),
        meta: { permissions: ["fuels-types-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-fuel-type",
        component: () => import("@/modules/fuels-types/pages/FuelTypeEdit.vue"),
        meta: { permissions: ["fuels-types-update"] }
      }
    ]
  }
];
