import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_SUBCONTRACTORS_EXPENSES_MATERIALS =
  "fetchSubcontractorExpensesMaterials";
export const FETCH_SUBCONTRACTORS_EXPENSE_MATERIALS =
  "fetchSubcontractorExpenseMaterials";
export const SAVE_SUBCONTRACTORS_EXPENSE_MATERIALS =
  "saveSubcontractorExpenseMaterials";
export const UPDATE_SUBCONTRACTORS_EXPENSE_MATERIALS =
  "updateSubcontractorExpenseMaterials";
export const DELETE_SUBCONTRACTORS_EXPENSE_MATERIALS =
  "deleteSubcontractorExpenseMaterials";
export const DELETE_SUBCONTRACTORS_EXPENSE_BOL_MATERIALS =
  "deleteInvoiceProtocolBOLMaterials";
export const GENERATE_MATERIAL_PDF_URL = "generateMaterialPdfUrl";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingSubcontractorExpense";
export const SET_SUBCONTRACTORS_EXPENSE_MATERIALS =
  "setSubcontractorExpenseMaterials";

const state = {
  errors: [],
  subcontractor_expense_materials: [],
  isLoadingSucontractorExpenseMaterials: false,
};

const getters = {
  isLoadingSucontractorExpenseMaterials() {
    return state.isLoadingSucontractorExpenseMaterials;
  },
  getSubcontractorExpenseMaterials() {
    return state.subcontractor_expense_materials;
  },
};

const actions = {
  [FETCH_SUBCONTRACTORS_EXPENSES_MATERIALS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("subcontractors-invoices-materials" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_SUBCONTRACTORS_EXPENSE_MATERIALS](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("subcontractors-invoices-materials", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_SUBCONTRACTORS_EXPENSE_MATERIALS](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("subcontractors-invoices-materials", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_SUBCONTRACTORS_EXPENSE_MATERIALS](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("subcontractors-invoices-materials", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_SUBCONTRACTORS_EXPENSE_MATERIALS](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("subcontractors-invoices-materials", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_SUBCONTRACTORS_EXPENSE_BOL_MATERIALS](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("subcontractors-invoices-bol-materials", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_MATERIAL_PDF_URL](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`subcontractors-invoices-materials/${id}/generate-pdf-url`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingSucontractorExpenseMaterials = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingSucontractorExpense = value;
  },
  [SET_SUBCONTRACTORS_EXPENSE_MATERIALS](state, data) {
    state.isLoadingSucontractorExpenseMaterials = false;
    state.subcontractor_expense_materials = data.data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
