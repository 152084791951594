import Vue from "vue";
import Router from "vue-router";

import UserRouter from "@/modules/user/router.js";
import RoleRouter from "@/modules/role/router.js";

import AssignorsGroups from "@/modules/assignorsgroups/router.js";
import Assignors from "@/modules/assignors/router.js";
import Staff from "@/modules/staff/router.js";
import StaffVehicle from "@/modules/staff-vehicle/router.js";

import MachinesTypesRouter from "@/modules/machines-types/router.js";
import Machines from "@/modules/machines/router.js";
import RentedMachines from "@/modules/rented-machines/router.js";
import Subcontractors from "@/modules/subcontractors/router.js";
import SubcontractorsTypes from "@/modules/subcontractors-types/router.js";
import FuelsTypes from "@/modules/fuels-types/router.js";
import GasStations from "@/modules/gas-stations/router.js";
import AssignorLocationRouter from "@/modules/assignorslocation/router.js";
import SubcontractorLocationRouter from "@/modules/subcontractorslocation/router.js";
import Tanks from "@/modules/tanks/router.js";
import TankCharges from "@/modules/tank-charges/router.js";
import FuelExpenses from "@/modules/fuel-expenses/router.js";
import FuelExpensesRow from "@/modules/fuel-expenses-row/router.js";
import Services from "@/modules/services/router.js";
import Measures from "@/modules/measures/router.js";
import BillOfLading from "@/modules/bill-of-lading/router.js";
import SubcontractorsExpenses from "@/modules/subcontractor-expenses/router.js";
import SubcontractorsExpensesMaterials from "@/modules/subcontractor-expenses-materials/router.js";
import Protocols from "@/modules/protocols/router.js";
import Statistics from "@/modules/statistics/router.js";
import Assignments from "@/modules/assignments/router.js";
import ServiceTypes from "@/modules/service-types/router.js";
import ServiceWarehouses from "@/modules/service-warehouses/router.js";
import ServiceWarehouseItems from "@/modules/service-warehouse-items/router.js";
import ServiceVendors from "@/modules/service-vendors/router.js";
import ServiceMachines from "@/modules/service-machines/router.js";
import ServiceInvoices from "@/modules/service-invoices/router.js";
import ServiceInvoiceRows from "@/modules/service-invoice-rows/router.js";
import ServiceMachineRows from "@/modules/service-machine-rows/router.js";

import { authGuard } from "@/guard.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        ...UserRouter,
        ...RoleRouter,
        //new
        ...AssignorsGroups,
        ...Assignors,
        ...Staff,
        ...StaffVehicle,
        ...MachinesTypesRouter,
        ...Machines,
        ...RentedMachines,
        ...Subcontractors,
        ...SubcontractorsTypes,
        ...FuelsTypes,
        ...GasStations,
        ...AssignorLocationRouter,
        ...SubcontractorLocationRouter,
        ...TankCharges,
        ...Tanks,
        ...FuelExpenses,
        ...Services,
        ...Measures,
        ...BillOfLading,
        ...SubcontractorsExpenses,
        ...Protocols,
        ...SubcontractorsExpensesMaterials,
        ...Statistics,
        ...FuelExpensesRow,
        ...Assignments,
        ...ServiceTypes,
        ...ServiceWarehouses,
        ...ServiceWarehouseItems,
        ...ServiceVendors,
        ...ServiceMachines,
        ...ServiceInvoices,
        ...ServiceInvoiceRows,
        ...ServiceMachineRows
      ],
      meta: { requiresAuth: true }
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "twoFactor",
          path: "/login/2",
          component: () => import("@/view/pages/auth/TwoFactor")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        },
        {
          name: "forgot",
          path: "/forgot",
          component: () => import("@/view/pages/auth/Forgot")
        },
        {
          name: "reset",
          path: "/reset/:token/:email",
          component: () => import("@/view/pages/auth/Reset")
        }
      ],
      meta: { requiresAuth: false }
    },
    {
      path: "*",
      redirect: "/404",
      meta: { requiresAuth: false }
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => {
        return import("@/view/pages/error/Error-4.vue");
      },
      meta: { requiresAuth: false }
    }
  ]
});

router.beforeEach(authGuard);
export default router;
