export default [
  {
    path: "/bill-of-lading",
    name: "bill-of-lading",
    component: () => import("@/modules/bill-of-lading/BillOfLading.vue"),
    children: [
      {
        path: "list",
        name: "list-bill-of-lading",
        component: () =>
          import("@/modules/bill-of-lading/pages/BillOfLadingList.vue"),
        meta: { permissions: ["bill-of-lading-show"] },
      },
      {
        path: "add",
        name: "add-bill-of-lading",
        component: () =>
          import("@/modules/bill-of-lading/pages/BillOfLadingAdd.vue"),
        meta: { permissions: ["bill-of-lading-create"] },
        props: true,
      },
      {
        path: "edit/:id",
        name: "edit-bill-of-lading",
        component: () =>
          import("@/modules/bill-of-lading/pages/BillOfLadingEdit.vue"),
        meta: { permissions: ["bill-of-lading-update"] },
      },
    ],
  },
];
