import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_ASSIGNOR_LOCATIONS = "fetchAssignorLocations";
export const FETCH_ASSIGNOR_LOCATION = "fetchAssignorLocation";
export const SAVE_ASSIGNOR_LOCATION = "saveAssignorLocation";
export const UPDATE_ASSIGNOR_LOCATION = "updateAssignorLocation";
export const DELETE_ASSIGNOR_LOCATION = "deleteAssignorLocation";
export const FETCH_NEXT_CODE = "fetchNextCode";



//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingLocation";
export const SET_LOCATIONS = "setLocations";

const state = {
  errors: [],
  locations: [],
  isLoadingAssignorLocation: false
};

const getters = {
  isLoadingAssignorLocation() {
    return state.isLoadingAssignorLocation;
  },
  getAssignorLocations() {
    return state.locations;
  }
};

const actions = {
  [FETCH_ASSIGNOR_LOCATIONS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("assignor-locations" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_ASSIGNOR_LOCATION](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("assignor-locations", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_NEXT_CODE](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("assignor-locations-next-code")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_ASSIGNOR_LOCATION](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("assignor-locations", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_ASSIGNOR_LOCATION](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("assignor-locations", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_ASSIGNOR_LOCATION](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("assignor-locations", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingAssignorLocation = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingAssignorLocation = value;
  },
  [SET_LOCATIONS](state, data) {
    state.isLoadingAssignorLocation = false;
    state.locations = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
