import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_SUBCONTRACTORS = "fetchSubcontractors";
export const FETCH_SUBCONTRACTOR = "fetchSubcontractor";
export const SAVE_SUBCONTRACTOR = "saveSubcontractor";
export const UPDATE_SUBCONTRACTOR = "updateSubcontractor";
export const DELETE_SUBCONTRACTOR = "deleteSubcontractor";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingSubcontractor";
export const SET_SUBCONTRACTORS = "setSubcontractors";

const state = {
  errors: [],
  subcontractor: [],
  isLoadingSubcontractor: false
};

const getters = {
  isLoadingSubcontractor() {
    return state.isLoadingSubcontractor;
  },
  getSubcontractor() {
    return state.subcontractor
  }
};

const actions = {
  [FETCH_SUBCONTRACTORS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("subcontractors" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_SUBCONTRACTOR](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("subcontractors", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_SUBCONTRACTOR](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("subcontractors", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_SUBCONTRACTOR](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("subcontractors", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_SUBCONTRACTOR](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("subcontractors", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingSubcontractor = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingSubcontractor = value;
  },
  [SET_SUBCONTRACTORS](state, data) {
    state.isLoadingSubcontractor = false;
    state.subcontractor = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
