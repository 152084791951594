import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_ALL_STAFF_VEHICLE = "fetchAllStaffVehicle";
export const FETCH_STAFF_VEHICLE = "fetchStaffVehicle";
export const SAVE_STAFF_VEHICLE = "saveStaffVehicle";
export const UPDATE_STAFF_VEHICLE = "updateStaffVehicle";
export const DELETE_STAFF_VEHICLE = "deleteStaffVehicle";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingStaffVehicle";
export const SET_STAFF_VEHICLE = "setStaffVehicle";

const state = {
  errors: [],
  staff_vehicle: [],
  isLoadingStaffVehicle: false
};

const getters = {
  isLoadingStaffVehicle() {
    return state.isLoadingStaffVehicle;
  },
  getStaffVehicle() {
    return state.staff_vehicle;
  }
};

const actions = {
  [FETCH_ALL_STAFF_VEHICLE](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("staff-vehicle" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_STAFF_VEHICLE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("staff-vehicle", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_STAFF_VEHICLE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("staff-vehicle", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_STAFF_VEHICLE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("staff-vehicle", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_STAFF_VEHICLE](context, id) {
    context.commit(SET_LOADING, true);
    
    return new Promise((resolve, reject) => {
      ApiService.delete("staff-vehicle", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingStaffVehicle = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingStaffVehicle = value;
  },
  [SET_STAFF_VEHICLE](state, data) {
    state.isLoadingStaffVehicle = false;
    state.staff_vehicle = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
