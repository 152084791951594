import JwtService from "@/core/services/jwt.service";
import store from "@/core/services/store";
import { REFRESH_USER_DATA } from "@/core/services/store/auth.module";
/**
 * Set the default HTTP request headers
 */
const isAuthenticated = () => {
  return !!JwtService.getToken();
};
/**
 * Service to call HTTP request via Axios
 */
const AuthService = {
  init() {
    if (isAuthenticated()) {
  //#todo ulock later    store.dispatch(REFRESH_USER_DATA).then(function() {});
    }
  }
};

export default AuthService;
