import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const GENERATE_FUEL_AVAILABLITIY_PDF_URL =
  "generateFuelAvailabilityPdfUrl";
export const GENERATE_MONTH_BY_MACHINE_PDF_URL = "generateMonthByMachinePdfUrl";
export const GENERATE_EXPENSES_BY_MACHINE_PDF_URL =
  "generateExpensesByMachinePdfUrl";
export const GENERATE_FUEL_BY_SUBCONTRACTOR_PDF_URL =
  "generateFuelBySubcontractorPdfUrl";
export const GENERATE_FUEL_BY_OWN_PDF_URL = "generateFuelByOwnPdfUrl";
export const GENERATE_FUEL_STAFF_PDF_URL = "generateFuelStaffPdfUrl";
export const GENERATE_FUEL_STAFF_EXCEL_URL = "generateFuelStaffExcelUrl";
export const GENERATE_FUEL_FULL_PDF_URL = "generateFuelFullPdfUrl";
export const GENERATE_FUEL_BY_DAY_EXCEL_URL = "generateFuelByDayPdfUrl";
export const GENERATE_BOL_WORK_EXCEL_URL = "generateBolWorkExcelUrl";
export const GENERATE_WAREHOUSE_ITEM_AVAILABLITIY_PDF_URL =
  "generateWarehouseItemAvailabilityPdfUrl";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingStat";

const state = {
  errors: [],
  isLoadingStat: false
};

const getters = {
  isLoadingStat() {
    return state.isLoadingStat;
  }
};

const actions = {
  [GENERATE_MONTH_BY_MACHINE_PDF_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`stats/generate-month-by-machine-pdf-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_EXPENSES_BY_MACHINE_PDF_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`stats/generate-expenses-by-machine-pdf-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_FUEL_AVAILABLITIY_PDF_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`stats/generate-fuel-availability-pdf-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_FUEL_BY_SUBCONTRACTOR_PDF_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`stats/generate-fuel-by-subcontractor-pdf-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_FUEL_BY_OWN_PDF_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`stats/generate-fuel-own-pdf-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_FUEL_STAFF_PDF_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`stats/generate-fuel-staff-pdf-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_FUEL_STAFF_EXCEL_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`stats/generate-fuel-staff-excel-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_FUEL_FULL_PDF_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`stats/generate-fuel-full-pdf-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_FUEL_BY_DAY_EXCEL_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`stats/generate-fuel-by-day-excel-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_BOL_WORK_EXCEL_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`stats/generate-bol-work-excel-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_WAREHOUSE_ITEM_AVAILABLITIY_PDF_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`stats/generate-warehouse-item-availability-pdf-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingStat = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingStat = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
