import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { OauthClient } from "@/core/config/config.js";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const REFRESH_TOKEN = "refreshToken";
export const REFRESH_USER_DATA = "refreshUserData";
export const EMAIL = "email";
export const REMEMBER = "remember";
export const CHECK_TOKEN = "checkToken";
export const FORGOT = "forgot";
export const RESET = "reset";
export const TWOFACTOR = "twoFactor";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_USER_DATA = "setUserData";
export const UPDATE_USER_DATA = "updateUserData";

export const PASSWORD_GRANT = "password";
export const REFRESH_TOKEN_GRANT = "refresh_token";
export const REFRESH_TOKEN_KEY = "refresh_token";

const state = {
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      const postData = new OauthClient(PASSWORD_GRANT, credentials);
      postData.remember = JwtService.getRemember();

      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_ERROR, []);
          if (data) {
            //if (!data.data["2fa_sent"]) {
            context.commit(SET_AUTH, data);
            //}
            resolve(data);
          }
        })
        .catch(({ response }) => {
          if (response.status === 400) {
            response.data.errors.info = response.data.error;
          }
          context.commit(SET_ERROR, response.data.message);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve) => {
      ApiService.post("logout")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors.info);
        })
        .finally(() => {
          context.commit(PURGE_AUTH);
        });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors.info);
          reject(response);
        });
    });
  },
  [FORGOT](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("forgot", { email: credentials })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.status === 400) {
            response.data.errors.info = "Email not found!";
          }
          context.commit(SET_ERROR, response.data.errors.info);
          reject(response);
        });
    });
  },
  [RESET](context, payload) {
    return new Promise((resolve, reject) => {
      const { email, password, password_confirmation, token } = payload;
      const form = { email, password, password_confirmation, token };
      ApiService.post("reset", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.status === 404) {
            response.data.errors.info = response.data.error;
          }
          context.commit(SET_ERROR, response.data.errors.info);
          reject(response);
        });
    });
  },
  [TWOFACTOR](context, payload) {
    const { code } = payload;
    return new Promise((resolve, reject) => {
      ApiService.get("two-factor", code)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // console.log(response);
          if (response.data.status === 404) {
            response.data.errors.info = response.data.error;
          }
          context.commit(SET_ERROR, response.data.errors.info);
          reject(response);
        });
    });
  },
  [REFRESH_USER_DATA](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("refresh-user-data")
        .then(({ data }) => {
          context.commit(UPDATE_USER_DATA, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors.info);
          reject(response);
        });
    });
  },
  [REFRESH_TOKEN](context) {
    return new Promise((resolve, reject) => {
      var oauthClientParams = {};
      oauthClientParams[REFRESH_TOKEN_KEY] = JwtService.getRefreshToken();
      oauthClientParams[EMAIL] = JwtService.getUser().email;
      oauthClientParams[REMEMBER] = JwtService.getRemember();
      const postData = new OauthClient(REFRESH_TOKEN_GRANT, oauthClientParams);

      ApiService.post("refresh", postData)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors.info);
          reject(response);
        });
    });
  },
  [CHECK_TOKEN](context) {
    return new Promise((resolve, reject) => {
      if (!state.isAuthenticated) {
        resolve(state.user);
      } else {
        actions[REFRESH_TOKEN](context)
          .then((data) => {
            resolve(data);
          })
          .catch((response) => {
            actions[LOGOUT](context);
            context.commit(SET_ERROR, response.data.errors.info);
            reject(response);
          });
      }
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data.data.userData;
    state.errors = [];
    JwtService.saveTokens(data.data);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = [];
    JwtService.destroyTokens();
  },
  [UPDATE_USER_DATA](state, data) {
    state.user = data;
    JwtService.updateUserData(data);
  },
  [SET_USER_DATA](state, data) {
    state.user = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
