import Vue from "vue";
import Vuex from "vuex";

//Core
import shared from "./shared.js";

//Core Custom
import lang from "./lang.module";

//Module Modules
import user from "../../../modules/user/store/user.module";
import role from "../../../modules/role/store/role.module";

import assignorsgroups from "../../../modules/assignorsgroups/store/assignorsgroups.module";
import assignors from "../../../modules/assignors/store/assignors.module";
import staff from "../../../modules/staff/store/staff.module";
import staff_vehicle from "../../../modules/staff-vehicle/store/staffvehicle.module";
import machinestypes from "../../../modules/machines-types/store/machinetype.module";
import machines from "../../../modules/machines/store/machine.module";
import rented_machines from "../../../modules/rented-machines/store/rentedmachine.module";
import subcontractors from "../../../modules/subcontractors/store/subcontractor.module";
import subcontractors_types from "../../../modules/subcontractors-types/store/subcontractor-type.module";
import fuels_types from "../../../modules/fuels-types/store/fuel-type.module";
import gas_stations from "../../../modules/gas-stations/store/gasstation.module";
import assignor_location from "../../../modules/assignorslocation/store/location.module";
import subcontractor_location from "../../../modules/subcontractorslocation/store/location.module";
import tank_charges from "../../../modules/tank-charges/store/tankcharges.module";
import tanks from "../../../modules/tanks/store/tanks.module";
import fuel_expenses from "../../../modules/fuel-expenses/store/fuelexpenses.module";
import services from "../../../modules/services/store/service.module";
import location_services from "../../../modules/assignorslocation/store/location-service.module";
import measures from "../../../modules/measures/store/measures.module";
import bill_of_lading from "../../../modules/bill-of-lading/store/bill-of-lading.module";
import subcontractor_expenses from "../../../modules/subcontractor-expenses/store/subcontractor-expenses.module";
import subcontractor_expenses_materials from "../../../modules/subcontractor-expenses-materials/store/subcontractor-expenses-materials.module";
import protocols from "../../../modules/protocols/store/protocol.module";
import statistics from "../../../modules/statistics/store/statistics.module";
import fuel_expenses_row from "../../../modules/fuel-expenses-row/store/fuelexpensesrow.module";
import assignments from "../../../modules/assignments/store/assignment.module";
import assignment_notifications from "../../../modules/assignments/store/assignment-notification.module";
import viber_log from "../../../modules/assignments/store/viber-log.module";
import service_type from "../../../modules/service-types/store/service-type.module";
import service_warehouses from "../../../modules/service-warehouses/store/service-warehouses.module";
import service_warehouse_items from "../../../modules/service-warehouse-items/store/service-warehouse-items.module";
import service_vendors from "../../../modules/service-vendors/store/service-vendors.module";
import service_machines from "../../../modules/service-machines/store/service-machines.module";
import service_invoices from "../../../modules/service-invoices/store/service-invoices.module";
import service_invoice_rows from "../../../modules/service-invoice-rows/store/service-invoice-rows.module";
import service_groups from "../../../modules/service-groups/store/service-groups.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ...shared,
    lang,
    user,
    role,
    assignorsgroups,
    assignors,
    staff,
    staff_vehicle,
    machinestypes,
    machines,
    rented_machines,
    subcontractors,
    subcontractors_types,
    fuels_types,
    gas_stations,
    assignor_location,
    subcontractor_location,
    tank_charges,
    tanks,
    fuel_expenses,
    services,
    measures,
    location_services,
    bill_of_lading,
    subcontractor_expenses,
    subcontractor_expenses_materials,
    protocols,
    statistics,
    fuel_expenses_row,
    assignments,
    assignment_notifications,
    viber_log,
    service_type,
    service_warehouses,
    service_vendors,
    service_machines,
    service_invoices,
    service_invoice_rows,
    service_warehouse_items,
    service_groups
  }
});
