export default [
  {
    path: "/protocols",
    name: "protocols",
    component: () => import("@/modules/protocols/Protocols.vue"),
    children: [
      {
        path: "list",
        name: "list-protocols",
        component: () => import("@/modules/protocols/pages/ProtocolList.vue"),
        meta: { permissions: ["protocols-show"] }
      },
      {
        path: "add",
        name: "add-protocols",
        component: () => import("@/modules/protocols/pages/ProtocolAdd.vue"),
        props: true,
        meta: { permissions: ["protocols-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-protocols",
        component: () => import("@/modules/protocols/pages/ProtocolEdit.vue"),
        meta: { permissions: ["protocols-update"] }
      }
    ]
  }
];
