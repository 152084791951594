import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_FUEL_EXPENSES_ROW = "fetchFuelExpensesRow";
export const FETCH_FUEL_EXPENSE_ROW = "fetchFuelExpenseRow";
export const SAVE_FUEL_EXPENSE_ROW = "saveFuelExpenseRow";
export const UPDATE_FUEL_EXPENSE_ROW = "updateFuelExpenseRow";
export const DELETE_FUEL_EXPENSE_ROW = "deleteFuelExpenseRow";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingFuelExpense";
export const SET_FUEL_EXPENSE_ROW = "setFuelExpenseRow";

const state = {
  errors: [],
  isLoadingFuelExpenseRow: false,
};

const getters = {
  isLoadingFuelExpenseRowRow() {
    return state.isLoadingFuelExpenseRowRow;
  },
};

const actions = {
  [FETCH_FUEL_EXPENSES_ROW](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fuel-expense-rows" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_FUEL_EXPENSE_ROW](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("fuel-expense-rows", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_FUEL_EXPENSE_ROW](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("fuel-expense-rows", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_FUEL_EXPENSE_ROW](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("fuel-expense-rows", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_FUEL_EXPENSE_ROW](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("fuel-expense-rows", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingFuelExpenseRow = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingFuelExpenseRow = value;
  },
  [SET_FUEL_EXPENSE_ROW](state, data) {
    state.isLoadingFuelExpenseRow = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
