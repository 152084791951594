export default [
  {
    path: "/tanks",
    name: "tank",
    component: () => import("@/modules/tanks/Tanks.vue"),
    children: [
      {
        path: "list",
        name: "list-tanks",
        component: () => import("@/modules/tanks/pages/TanksList.vue"),
        meta: { permissions: ["tanks-show"] }
      },
      {
        path: "add",
        name: "add-tanks",
        component: () => import("@/modules/tanks/pages/TanksAdd.vue"),
        meta: { permissions: ["tanks-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-tanks",
        component: () => import("@/modules/tanks/pages/TanksEdit.vue"),
        meta: { permissions: ["tanks-update"] }
      }
    ]
  }
];
