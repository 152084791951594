import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_MACHINES_FOR_SERVICE = "fetchMachinesForService";
export const FETCH_MACHINE_FOR_SERVICE = "fetchMachineForService";
export const FETCH_SERVICE_MACHINES = "fetchServiceMachines";
export const FETCH_SERVICE_MACHINE = "fetchServiceMachine";
export const SAVE_SERVICE_MACHINE = "saveServiceMachine";
export const UPDATE_SERVICE_MACHINE = "updateServiceMachine";
export const DELETE_SERVICE_MACHINE = "deleteServiceMachine";
export const GENERATE_EXPORT_SERVICE_MACHINE_URL =
  "generateExportServiceMachineUrl";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingServiceMachine";

const state = {
  errors: [],
  isLoadingServiceMachine: false
};

const getters = {
  isLoadingServiceMachine() {
    return state.isLoadingServiceMachine;
  }
};

const actions = {
  [FETCH_MACHINES_FOR_SERVICE](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("machines-for-service" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_MACHINE_FOR_SERVICE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("machines-for-service", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_SERVICE_MACHINE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("service-machines", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_SERVICE_MACHINE](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("service-machines", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_SERVICE_MACHINE](context, params) {
    context.commit(SET_LOADING, true);

    const id = params.id;

    return new Promise((resolve, reject) => {
      ApiService.update("service-machines", id, params.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_SERVICE_MACHINE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("service-machines", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_EXPORT_SERVICE_MACHINE_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`/generate-export-service-machine-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingServiceMachine = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingServiceMachine = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
