import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_BILL_OF_LADINGS = "fetchBillOfLadings";
export const FETCH_BILL_OF_LADING = "fetchBillOfLading";
export const SAVE_BILL_OF_LADING = "saveBillOfLading";
export const UPDATE_BILL_OF_LADING = "updateBillOfLading";
export const DELETE_BILL_OF_LADING = "deleteBillOfLading";
export const DELETE_BILL_OF_LADING_WORK = "deleteBillOfLadingWork";
export const SEARCH_BILL_OF_LADING_WORK = "searchBillOfLadingWork";
export const SEARCH_BILL_OF_LADING_PROTOCOLS = "searchBillOfLadingProtocols";
export const SEARCH_BILL_OF_LADING_MATERIALS = "searchBillOfLadingMaterials";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingBillOfLading";
export const SET_BILL_OF_LADING = "setBillOfLading";

const state = {
  errors: [],
  bill_of_lading: [],
  isLoadingBillOfLading: false,
};

const getters = {
  isLoadingBillOfLading() {
    return state.isLoadingBillOfLading;
  },
  getBillOfLading() {
    return state.bill_of_lading;
  },
};

const actions = {
  [FETCH_BILL_OF_LADINGS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("bill-of-lading" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SEARCH_BILL_OF_LADING_WORK](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("find-bill-of-lading-work" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SEARCH_BILL_OF_LADING_MATERIALS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("find-bill-of-lading-materials" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [SEARCH_BILL_OF_LADING_PROTOCOLS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("find-bill-of-lading-protocols" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [FETCH_BILL_OF_LADING](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("bill-of-lading", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_BILL_OF_LADING](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("bill-of-lading", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_BILL_OF_LADING](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("bill-of-lading", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_BILL_OF_LADING](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("bill-of-lading", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_BILL_OF_LADING_WORK](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("bill-of-lading-work", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingBillOfLading = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingBillOfLading = value;
  },
  [SET_BILL_OF_LADING](state, data) {
    state.isLoadingBillOfLading = false;
    state.bill_of_lading = data.data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
