const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const EXPIRES_AT_KEY = "expires_at";
const REMEMBER_TOKEN_KEY = "remember";
const USER = "userData";

export const getToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const getExpiresAt = () => {
  return parseInt(window.localStorage.getItem(EXPIRES_AT_KEY));
};

export const getRemember = () => {
  return window.localStorage.getItem(REMEMBER_TOKEN_KEY);
};

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem(USER));
};

export const saveTokens = tokens => {
  window.localStorage.setItem(ACCESS_TOKEN_KEY, tokens.accessToken);
  window.localStorage.setItem(REMEMBER_TOKEN_KEY, tokens.rememberToken);
  window.localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refreshToken);
  window.localStorage.setItem(USER, JSON.stringify(tokens.userData));
  const expiresAt = Date.now() + tokens.expires_in;
  window.localStorage.setItem(EXPIRES_AT_KEY, expiresAt);
};

export const updateUserData = userData => {
  window.localStorage.setItem(USER, JSON.stringify(userData));
};

export const destroyTokens = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  window.localStorage.removeItem(EXPIRES_AT_KEY);
  window.localStorage.removeItem(USER);
};

export default {
  getToken,
  getRefreshToken,
  getExpiresAt,
  saveTokens,
  updateUserData,
  destroyTokens,
  getRemember,
  getUser
};
