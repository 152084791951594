export default [
  {
    path: "fuel-expenses/:expenseId/fuel-expenses-row",
    name: "fuel-expenses-row",
    component: () => import("@/modules/fuel-expenses-row/FuelExpensesRow.vue"),
    children: [
      {
        path: "add",
        name: "add-fuel-expenses-row",
        component: () =>
          import("@/modules/fuel-expenses-row/pages/FuelExpensesRowAdd.vue"),
        meta: { permissions: ["fuel-expenses-create"] },
      },
      {
        path: "edit/:id",
        name: "edit-fuel-expenses-row",
        component: () =>
          import("@/modules/fuel-expenses-row/pages/FuelExpensesRowEdit.vue"),
        meta: { permissions: ["fuel-expenses-update"] },
      },
    ],
  },
];
