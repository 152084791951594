import ApiService from "@/core/services/api.service";

//Actions
export const GENERATE_DOWNLOAD_FILE_URL = "generateDownloadFileUrl";
export const SAVE_FILE = "saveFile";
export const DELETE_FILE = "deleteFile";

//Mutations
export const SET_LOADING = "setLoadingSharedFile";

const state = {
  isLoadingSharedFile: false
};

const getters = {
  isLoadingSharedFile() {
    return state.isLoadingSharedFile;
  }
};

const actions = {
  [SAVE_FILE](context, payload) {
    context.commit(SET_LOADING, true);
    const headers = { "Content-Type": "multipart/form-data" };
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("file", payload.file);
    formData.append("foreign_id", payload.foreign_id);
    formData.append("foreign_type", payload.foreign_type);
    return new Promise((resolve, reject) => {
      ApiService.post("files", formData, { headers })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_DOWNLOAD_FILE_URL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`files/${payload}/generate-download-file-url`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_FILE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("files", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_LOADING](state, value) {
    state.isLoadingSharedFile = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
