import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_SUBCONTRACTORS_EXPENSES = "fetchSubcontractorExpenses";
export const FETCH_SUBCONTRACTORS_EXPENSE = "fetchSubcontractorExpense";
export const SAVE_SUBCONTRACTORS_EXPENSE = "saveSubcontractorExpense";
export const UPDATE_SUBCONTRACTORS_EXPENSE = "updateSubcontractorExpense";
export const DELETE_SUBCONTRACTORS_EXPENSE = "deleteSubcontractorExpense";
export const DELETE_SUBCONTRACTORS_EXPENSE_BOL = "deleteInvoiceProtocolBOL";
export const GENERATE_SUBCONTRACTOR_PDF_URL = "generateSubcontractorPdfUrl";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingSubcontractorExpense";
export const SET_SUBCONTRACTORS_EXPENSE = "setSubcontractorExpense";

const state = {
  errors: [],
  subcontractor_expense: [],
  isLoadingSucontractorExpense: false,
};

const getters = {
  isLoadingSucontractorExpense() {
    return state.isLoadingSucontractorExpense;
  },
  getSubcontractorExpense() {
    return state.subcontractor_expense;
  },
};

const actions = {
  [FETCH_SUBCONTRACTORS_EXPENSES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("subcontractors-invoices" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_SUBCONTRACTORS_EXPENSE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("subcontractors-invoices", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_SUBCONTRACTORS_EXPENSE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("subcontractors-invoices", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_SUBCONTRACTORS_EXPENSE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("subcontractors-invoices", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_SUBCONTRACTORS_EXPENSE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("subcontractors-invoices", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_SUBCONTRACTORS_EXPENSE_BOL](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("subcontractors-invoices-bol", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_SUBCONTRACTOR_PDF_URL](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`subcontractors-invoices/${id}/generate-pdf-url`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingSucontractorExpense = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingSucontractorExpense = value;
  },
  [SET_SUBCONTRACTORS_EXPENSE](state, data) {
    state.isLoadingSucontractorExpense = false;
    state.subcontractor_expense = data.data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
