export default [
  {
    path: "/subcontractor-expenses-materials",
    name: "subcontractor-expenses-materials",
    component: () => import("@/modules/subcontractor-expenses-materials/SubcontractorExpensesMaterials.vue"),
    children: [
      {
        path: "list",
        name: "list-subcontractor-expenses-materials",
        component: () => import("@/modules/subcontractor-expenses-materials/pages/SubcontractorExpensesMaterialsList.vue"),
        meta: { permissions: ["subcontractors-invoices-materials-show"] }
      },
      {
        path: "add",
        name: "add-subcontractor-expenses-materials",
        component: () => import("@/modules/subcontractor-expenses-materials/pages/SubcontractorExpensesMaterialsAdd.vue"),
        meta: { permissions: ["subcontractors-invoices-materials-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-subcontractor-expenses-materials",
        component: () => import("@/modules/subcontractor-expenses-materials/pages/SubcontractorExpensesMaterialsEdit.vue"),
        meta: { permissions: ["subcontractors-invoices-materials-update"] }
      }
    ]
  }
];
