export default [
  {
    path: "/tank-charges",
    name: "tank-charges",
    component: () => import("@/modules/tank-charges/TankCharges.vue"),
    children: [
      {
        path: "list",
        name: "list-tank-charges",
        component: () => import("@/modules/tank-charges/pages/TankChargesList.vue"),
        meta: { permissions: ["tank-charges-show"] }
      },
      {
        path: "add",
        name: "add-tank-charges",
        component: () => import("@/modules/tank-charges/pages/TankChargesAdd.vue"),
        meta: { permissions: ["tank-charges-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-tank-charges",
        component: () => import("@/modules/tank-charges/pages/TankChargesEdit.vue"),
        meta: { permissions: ["tank-charges-update"] }
      }
    ]
  }
];
