export default [
  {
    path: "/machines",
    name: "machines",
    component: () => import("@/modules/machines/Machines.vue"),
    children: [
      {
        path: "list",
        name: "list-machine",
        component: () => import("@/modules/machines/pages/MachineList.vue"),
        meta: { permissions: ["machines-show"] }
      },
      {
        path: "add",
        name: "add-machine",
        component: () => import("@/modules/machines/pages/MachineAdd.vue"),
        meta: { permissions: ["machines-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-machine",
        component: () => import("@/modules/machines/pages/MachineEdit.vue"),
        meta: { permissions: ["machines-update"] }
      },
      {
        path: "files/:id",
        name: "files-machine",
        component: () => import("@/modules/machines/pages/MachineFile.vue"),
        meta: { permissions: ["machines-update"] }
      }
    ]
  }
];
