export default [
  {
    path: "/service-types",
    name: "service-types",
    component: () => import("@/modules/service-types/ServiceTypes.vue"),
    children: [
      {
        path: "list",
        name: "list-service-types",
        component: () =>
          import("@/modules/service-types/pages/ServiceTypesList.vue"),
        meta: { permissions: ["service-show"] }
      },
      {
        path: "add",
        name: "add-service-types",
        component: () =>
          import("@/modules/service-types/pages/ServiceTypesAdd.vue"),
        meta: { permissions: ["service-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-service-types",
        component: () =>
          import("@/modules/service-types/pages/ServiceTypesEdit.vue"),
        meta: { permissions: ["service-update"] }
      }
    ]
  }
];
