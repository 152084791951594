//Core
import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";

//Module Modules
import shared_file from "./shared_file.module";
import shared_store from "./shared_store.module";
import shared_location from "./shared_location.module";
import shared_provider from "./shared_provider.module";
import shared_brand from "./shared_brand.module";
import shared_base from "./shared_base.module";
import shared_user from "./shared_user.module";
import shared_customer from "./shared_customer.module";
import gps from "./gps.module";

export default {
  auth,
  htmlClass,
  config,
  breadcrumbs,
  shared_file,
  shared_store,
  shared_location,
  shared_provider,
  shared_brand,
  shared_base,
  shared_user,
  shared_customer,
  gps,
};
