import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_PROTOCOLS = "fetchProtocols";
export const FETCH_PROTOCOL = "fetchProtocol";
export const SAVE_PROTOCOL = "saveProtocol";
export const DELETE_PROTOCOL = "deleteProtocol";
export const UPDATE_PROTOCOL = "updateProtocol";
export const FINALIZE_PROTOCOL = "finalizeProtocol";
export const GENERATE_PDF_URL = "generatePdfUrl";
export const GENERATE_EXCEL_URL = "generateExcelUrl";
export const GENERATE_EXPORT_PROTOCOLS_URL = "generateExportProtocolsUrl";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingProtocol";
export const SET_PROTOCOLS = "setProtocols";

const state = {
  errors: [],
  protocol: [],
  isLoadingProtocol: false
};

const getters = {
  isLoadingProtocol() {
    return state.isLoadingProtocol;
  },
  getProtocol() {
    return state.protocol;
  }
};

const actions = {
  [FETCH_PROTOCOLS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("protocols" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_PROTOCOL](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("protocols", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_PROTOCOL](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("protocols", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_PROTOCOL](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("protocols", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_PROTOCOL](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.delete("protocols", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FINALIZE_PROTOCOL](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post(`protocols/${id}/finalize`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_PDF_URL](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`protocols/${id}/generate-pdf-url`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_EXCEL_URL](context, id) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`protocols/${id}/generate-excel-url`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_EXPORT_PROTOCOLS_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`/generate-export-protocols-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingProtocol = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingSubcontractor = value;
  },
  [SET_PROTOCOLS](state, data) {
    state.isLoadingProtocol = false;
    state.protocol = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
