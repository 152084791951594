export default [
  {
    path: "/service-vendors",
    name: "service-vendors",
    component: () =>
      import("@/modules/service-vendors/ServiceVendors.vue"),
    children: [
      {
        path: "list",
        name: "list-service-vendors",
        component: () =>
          import(
            "@/modules/service-vendors/pages/ServiceVendorsList.vue"
          ),
        meta: { permissions: ["service-show"] }
      },
      {
        path: "add",
        name: "add-service-vendors",
        component: () =>
          import("@/modules/service-vendors/pages/ServiceVendorsAdd.vue"),
        meta: { permissions: ["service-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-service-vendors",
        component: () =>
          import(
            "@/modules/service-vendors/pages/ServiceVendorsEdit.vue"
          ),
        meta: { permissions: ["service-update"] }
      }
    ]
  }
];
