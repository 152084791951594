import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_SERVICE_INVOICES = "fetchServiceInvoices";
export const FETCH_SERVICE_INVOICE = "fetchServiceInvoice";
export const SAVE_SERVICE_INVOICE = "saveServiceInvoice";
export const UPDATE_SERVICE_INVOICE = "updateServiceInvoice";
export const DELETE_SERVICE_INVOICE = "deleteServiceInvoice";
export const GENERATE_EXPORT_SERVICE_INVOICE_ROWS_URL =
  "generateExportServiceInvoiceRowsUrl";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingServiceInvoice";

const state = {
  errors: [],
  isLoadingServiceInvoice: false
};

const getters = {
  isLoadingServiceInvoice() {
    return state.isLoadingServiceInvoice;
  }
};

const actions = {
  [FETCH_SERVICE_INVOICES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("service-invoices" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_SERVICE_INVOICE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("service-invoices", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_SERVICE_INVOICE](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("service-invoices", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_SERVICE_INVOICE](context, params) {
    context.commit(SET_LOADING, true);

    const id = params.id;

    return new Promise((resolve, reject) => {
      ApiService.update("service-invoices", id, params.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_SERVICE_INVOICE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("service-invoices", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_EXPORT_SERVICE_INVOICE_ROWS_URL](context, apiParams = "") {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`/generate-export-service-invoice-rows-url${apiParams}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingServiceInvoice = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingServiceInvoice = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
