import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_VIBER_LOG = "fetchViberLog";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingAssignmentNotifications";

const state = {
  errors: [],
  isLoadingViberLog: false
};

const getters = {
  isLoadingViberLog() {
    return state.isLoadingViberLog;
  }
};

const actions = {
  [FETCH_VIBER_LOG](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("viber-log" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingViberLog = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingViberLog = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
