import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_MACHINES = "fetchMachines";
export const FETCH_MACHINE = "fetchMachine";
export const SAVE_MACHINE = "saveMachine";
export const UPDATE_MACHINE = "updateMachine";
export const DELETE_MACHINE = "deleteMachine";
export const GPS_CHECK = "gpsCheck";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingMachine";
export const SET_MACHINE = "setMachine";

const state = {
  errors: [],
  machinesTypes: [],
  isLoadingAssignor: false,
};

const getters = {
  isLoadingMachine() {
    return state.isLoadingMachine;
  },
  getMachines() {
    return state.machines;
  },
};

const actions = {
  [FETCH_MACHINES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("machines" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_MACHINE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("machines", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_MACHINE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("machines", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_MACHINE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("machines", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_MACHINE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("machines", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GPS_CHECK](context, params) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(`machines/${params.id}/gps-check`, { work: params.work })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingMachine = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingMachine = value;
  },
  [SET_MACHINE](state, data) {
    state.isLoadingMachine = false;
    state.machines = data.data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
