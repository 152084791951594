export default [
  {
    path: "/assignors",
    name: "assignors",
    component: () => import("@/modules/assignors/Assignor.vue"),
    children: [
      {
        path: "list",
        name: "list-assignors",
        component: () => import("@/modules/assignors/pages/AssignorList.vue"),
        meta: { permissions: ["assignors-show"] }
      },
      {
        path: "add",
        name: "add-assignors",
        component: () => import("@/modules/assignors/pages/AssignorAdd.vue"),
        meta: { permissions: ["assignors-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-assignors",
        component: () => import("@/modules/assignors/pages/AssignorEdit.vue"),
        meta: { permissions: ["assignors-update"] }
      }
    ]
  }
];
