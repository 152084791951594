export default [
  {
    path: "/assignments",
    name: "assignments",
    component: () => import("@/modules/assignments/Assignments.vue"),
    children: [
      {
        path: "list",
        name: "list-assignment",
        component: () =>
          import("@/modules/assignments/pages/AssignmentList.vue"),
        meta: { permissions: ["assignments-show"] },
      },
      {
        path: "add",
        name: "add-assignment",
        component: () =>
          import("@/modules/assignments/pages/AssignmentAdd.vue"),
        meta: { permissions: ["assignments-create"] },
      },
      {
        path: "edit/:id",
        name: "edit-assignment",
        component: () =>
          import("@/modules/assignments/pages/AssignmentEdit.vue"),
        meta: { permissions: ["assignments-update"] },
      },
    ],
  },
];
