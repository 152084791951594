<template>
  <div>
    <v-skeleton-loader
      v-if="visible"
      :loading="visible"
      type="chip"
    ></v-skeleton-loader>
    <v-skeleton-loader
      v-if="visible"
      :loading="visible"
      type="card-heading"
    ></v-skeleton-loader>
  </div>
</template>
<script>
export default {
  name: "SkeletonLoaderMain",
  inheritAttrs: false,
  props: {
    /**
     * is it shown or not
     */
    visible: Boolean
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {}
};
</script>
