export default [
  {
    path: "/subcontractors-locations",
    name: "subcontractors-locations",
    component: () => import("@/modules/subcontractorslocation/Location.vue"),
    children: [
      {
        path: "list",
        name: "list-subcontractor-locations",
        component: () => import("@/modules/subcontractorslocation/pages/LocationList.vue"),
        meta: { permissions: ["subcontractor-locations-show"] }
      },
      {
        path: "add",
        name: "add-subcontractor-location",
        component: () => import("@/modules/subcontractorslocation/pages/LocationAdd.vue"),
        meta: { permissions: ["subcontractor-locations-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-subcontractor-location",
        component: () => import("@/modules/subcontractorslocation/pages/LocationEdit.vue"),
        meta: { permissions: ["subcontractor-locations-update"] }
      }
    ]
  }
];
