export default [
  {
    path: "/assignors-locations",
    name: "assignors-locations",
    component: () => import("@/modules/assignorslocation/Location.vue"),
    children: [
      {
        path: "list",
        name: "list-assignor-locations",
        component: () => import("@/modules/assignorslocation/pages/LocationList.vue"),
        meta: { permissions: ["assignor-locations-show"] }
      },
      {
        path: "add",
        name: "add-assignor-location",
        component: () => import("@/modules/assignorslocation/pages/LocationAdd.vue"),
        meta: { permissions: ["assignor-locations-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-assignor-location",
        component: () => import("@/modules/assignorslocation/pages/LocationEdit.vue"),
        meta: { permissions: ["assignor-locations-update"] }
      },
      {
        path: "location/:id",
        name: "edit-assignor-location-services",
        component: () => import("@/modules/assignorslocation/pages/LocationServices.vue"),
        meta: { permissions: ["assignor-locations-update"] }
      }
    ]
  }
];
