export default [
  {
    path: "/assignorsgroups",
    name: "assignorsgroups",
    component: () => import("@/modules/assignorsgroups/Assignorgroup.vue"),
    children: [
      {
        path: "list",
        name: "list-assignorsgroups",
        component: () => import("@/modules/assignorsgroups/pages/AssignorgroupList.vue"),
        meta: { permissions: ["assignors-groups-show"] }
      },
      {
        path: "add",
        name: "add-assignorsgroups",
        component: () => import("@/modules/assignorsgroups/pages/AssignorgroupAdd.vue"),
        meta: { permissions: ["assignors-groups-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-assignorsgroups",
        component: () => import("@/modules/assignorsgroups/pages/AssignorgroupEdit.vue"),
        meta: { permissions: ["assignors-groups-update"] }
      }
    ]
  }
];
