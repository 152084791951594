export const PROJECT_NAME = process.env.VUE_APP_PROJECT_NAME;
export const BASE_API_URL = process.env.VUE_APP_BASE_API_URL;
export const AUTH_CLIENT_ID = process.env.VUE_APP_AUTH_CLIENT_ID;
export const AUTH_CLIENT_SECRET = process.env.VUE_APP_AUTH_CLIENT_SECRET;

export const API_URL = BASE_API_URL + "api/";
export var OauthClient = (grant, params) => {
  var clientData = {
    grant_type: grant,
    client_id: AUTH_CLIENT_ID,
    client_secret: AUTH_CLIENT_SECRET,
    scope: "full-access",
  };

  if (params && typeof params === "object") {
    clientData = Object.assign(clientData, params);
  }

  return clientData;
};

export default API_URL;
