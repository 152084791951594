export default [
  {
    path: "/gas-stations",
    name: "location",
    component: () => import("@/modules/gas-stations/Gas-station.vue"),
    children: [
      {
        path: "list",
        name: "list-gas-stations",
        component: () => import("@/modules/gas-stations/pages/GasStationList.vue"),
        meta: { permissions: ["gas-stations-show"] }
      },
      {
        path: "add",
        name: "add-gas-station",
        component: () => import("@/modules/gas-stations/pages/GasStationAdd.vue"),
        meta: { permissions: ["gas-stations-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-gas-station",
        component: () => import("@/modules/gas-stations/pages/GasStationEdit.vue"),
        meta: { permissions: ["gas-stations-update"] }
      }
    ]
  }
];
