import ApiService from "@/core/services/api.service";

//Actions

export const FETCH_LOCATION_SERVICES   = "fetchLocationServices";
export const SAVE_LOCATION_SERVICES   = "saveLocationServices";
export const UPDATE_LOCATION_SERVICES = "updateLocationServices";
export const DELETE_LOCATION_SERVICES = "deleteLocationServices";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingService";
export const SET_LOCATION_SERVICE = "setLocationService";

const state = {
  errors: [],
  location_services: [],
  isLoadingLocationService: false
};

const getters = {
  isLoadingLocationService() {
    return state.isLoadingLocationService;
  },
  getLocationServices() {
    return state.location_services;
  }
};

const actions = {
  [FETCH_LOCATION_SERVICES](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("location-services", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_LOCATION_SERVICES](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("location-services", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_LOCATION_SERVICES](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("location-services", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_LOCATION_SERVICES](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("location-services", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingLocationService = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingLocationService = value;
  },
  [SET_LOCATION_SERVICE](state, data) {
    state.isLoadingLocationService = false;
    state.location_services = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
