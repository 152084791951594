import Vue from "vue";

Vue.prototype.$errors = {
  format(backendErrors) {
    const formattedErrors = [];
    if (backendErrors) {
      Object.keys(backendErrors).forEach(be => {
        if (typeof backendErrors[be] === "object") {
          backendErrors[be] = Object.values(backendErrors[be]);
        }
        backendErrors[be].forEach(bec => {
          if (typeof bec === "string") {
            formattedErrors.push(bec);
          } else if (typeof bec === "object") {
            Object.keys(bec).forEach(beck => {
              if (typeof bec[beck] === "string") {
                formattedErrors.push(bec[beck]);
              } else {
                formattedErrors.push(bec[beck][0]);
              }
            });
          }
        });
      });
    }
    return formattedErrors;
  }
};
