import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_RENTED_MACHINES = "fetchRentedMachines";
export const FETCH_RENTED_MACHINE = "fetchRentedMachine";
export const SAVE_RENTED_MACHINE = "saveRentedMachine";
export const UPDATE_RENTED_MACHINE = "updateRentedMachine";
export const DELETE_RENTED_MACHINE = "deleteRentedMachine";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingRentedMachine";
export const SET_MACHINE = "setMachine";

const state = {
  errors: [],
  rented_machines: [],
  isLoadingRentedMachine: false,
};

const getters = {
  isLoadingRentedMachine() {
    return state.isLoadingRentedMachine;
  },
  getRentedMachines() {
    return state.rented_machines;
  },
};

const actions = {
  [FETCH_RENTED_MACHINES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("rented-machines" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_RENTED_MACHINE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get(`rented-machines/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_RENTED_MACHINE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("rented-machines", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_RENTED_MACHINE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("rented-machines", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_RENTED_MACHINE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("rented-machines", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingRentedMachine = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingRentedMachine = value;
  },
  [SET_MACHINE](state, data) {
    state.isLoadingRentedMachine = false;
    state.machines = data.data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
