import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_SUBCONTRACTOR_LOCATIONS = "fetchSubcontractorLocations";
export const FETCH_SUBCONTRACTOR_LOCATION = "fetchSubcontractorLocation";
export const SAVE_SUBCONTRACTOR_LOCATION = "saveSubcontractorLocation";
export const UPDATE_SUBCONTRACTOR_LOCATION = "updateSubcontractorLocation";
export const DELETE_SUBCONTRACTOR_LOCATION = "deleteSubcontractorLocation";


//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingLocation";
export const SET_LOCATIONS = "setLocations";

const state = {
  errors: [],
  locations: [],
  isLoadingLocation: false
};

const getters = {
  isLoadingLocation() {
    return state.isLoadingLocation;
  },
  getLocations() {
    return state.locations;
  }
};

const actions = {
  [FETCH_SUBCONTRACTOR_LOCATIONS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("subcontractor-locations" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_SUBCONTRACTOR_LOCATION](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("subcontractor-locations", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_SUBCONTRACTOR_LOCATION](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("subcontractor-locations", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_SUBCONTRACTOR_LOCATION](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("subcontractor-locations", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_SUBCONTRACTOR_LOCATION](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("subcontractor-locations", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingLocation = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingLocation = value;
  },
  [SET_LOCATIONS](state, data) {
    state.isLoadingLocation = false;
    state.locations = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
