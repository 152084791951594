export default [
  {
    path: "/fuel-expenses",
    name: "fuel-expenses",
    component: () => import("@/modules/fuel-expenses/FuelExpenses.vue"),
    children: [
      {
        path: "list",
        name: "list-fuel-expenses",
        component: () => import("@/modules/fuel-expenses/pages/FuelExpensesList.vue"),
        meta: { permissions: ["fuel-expenses-show"] }
      },
      {
        path: "add",
        name: "add-fuel-expenses",
        component: () => import("@/modules/fuel-expenses/pages/FuelExpensesAdd.vue"),
        meta: { permissions: ["fuel-expenses-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-fuel-expenses",
        component: () => import("@/modules/fuel-expenses/pages/FuelExpensesEdit.vue"),
        meta: { permissions: ["fuel-expenses-update"] }
      }
    ]
  }
];
