import store from "./core/services/store";
import JwtService from "@/core/services/jwt.service.js";
import { SET_USER_DATA } from "./core/services/store/auth.module";

export const authGuard = (to, from, next) => {
  // reset config to initial state
  // store.dispatch(RESET_LAYOUT_CONFIG);

  // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  const { requiresAuth } = to.matched[0].meta;
  // console.log(to.matched[0]);
  if (requiresAuth == true) {
    // console.log('auth-true');
    //Check first if is authenticated
    let isAuthenticated = !!JwtService.getToken();
    if (isAuthenticated == true) {
      //Check for Permissions
      const { permissions } = to.meta;
      let hasPermission = false;
      let userPermissions = [];
      let userData = JwtService.getUser();
      userPermissions = userData.permissions;
      //PreSet user state from localstorage to prevent missing content while is loading REFRESH_USER_DATA action in auth.module.
      store.commit(SET_USER_DATA, userData);
      if (
        !permissions ||
        (permissions &&
          permissions.length &&
          permissions.some((v) => userPermissions.includes(v)))
      ) {
        hasPermission = true;
      }

      if (hasPermission == true) {
        next();
      } else {
        const path = to.path.includes("edit")
          ? "/" + to.path.split("/")[1] + "/list"
          : "/";
        return next({ path: path });
      }
    } else {
      //Push to login page
      return next({ path: "login" });
    }
  } else {
    // console.log('auth-false');
    next();
  }
  next();

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
};
