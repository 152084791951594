import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_MACHINES_TYPES = "fetchMachinesTypes";
export const FETCH_MACHINE_TYPE  = "fetchMachineType";
export const SAVE_MACHINE_TYPE   = "saveMachineType";
export const UPDATE_MACHINE_TYPE = "updateMachineType";
export const DELETE_MACHINE_TYPE = "deleteMachineType";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingMachineType";
export const SET_MACHINE_TYPE = "setMachineType";

const state = {
  errors: [],
  machinesTypes: [],
  isLoadingMachineType: false
};

const getters = {
  isLoadingMachineType() {
    return state.isLoadingMachineType;
  },
  getMachinesTypes() {
    return state.machinesTypes;
  }
};

const actions = {
  [FETCH_MACHINES_TYPES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("machines-types" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_MACHINE_TYPE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("machines-types", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_MACHINE_TYPE](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("machines-types", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_MACHINE_TYPE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("machines-types", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_MACHINE_TYPE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("machines-types", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingMachineType = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingMachineType = value;
  },
  [SET_MACHINE_TYPE](state, data) {
    state.isLoadingMachineType = false;
    state.machinesTypes = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
