import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_MEASURES = "fetchMeasures";
export const FETCH_MEASURE  = "fetchMeasure";
export const SAVE_MEASURE   = "saveMeasure";
export const UPDATE_MEASURE = "updateMeasure";
export const DELETE_MEASURE = "deleteMeasure";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingMeasures";
export const SET_MEASURE = "setMeasure";

const state = {
  errors: [],
  measures: [],
  isLoadingMeasures: false
};

const getters = {
  isLoadingMeasures() {
    return state.isLoadingMeasures;
  },
  getMeasures() {
    return state.measures;
  }
};

const actions = {
  [FETCH_MEASURES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("measures" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_MEASURE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("measures", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_MEASURE](context, payload) {
    context.commit(SET_MEASURE, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("measures", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_MEASURE](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("measures", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_MEASURE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("measures", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingMeasures = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingMeasures = value;
  },
  [SET_MEASURE](state, data) {
    state.isLoadingMeasures = false;
    state.measures = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
