export default [
  {
    path: "/subcontractors",
    name: "subcontractors",
    component: () => import("@/modules/subcontractors/Subcontractors.vue"),
    children: [
      {
        path: "list",
        name: "list-subcontractor",
        component: () => import("@/modules/subcontractors/pages/SubcontractorList.vue"),
        meta: { permissions: ["subcontractors-show"] }
      },
      {
        path: "add",
        name: "add-subcontractor",
        component: () => import("@/modules/subcontractors/pages/SubcontractorAdd.vue"),
        meta: { permissions: ["subcontractors-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-subcontractor",
        component: () => import("@/modules/subcontractors/pages/SubcontractorEdit.vue"),
        meta: { permissions: ["subcontractors-update"] }
      }
    ]
  }
];
