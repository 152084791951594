export default [
  {
    path: "/subcontractor-expenses",
    name: "subcontractor-expenses",
    component: () => import("@/modules/subcontractor-expenses/SubcontractorExpenses.vue"),
    children: [
      {
        path: "list",
        name: "list-subcontractor-expenses",
        component: () => import("@/modules/subcontractor-expenses/pages/SubcontractorExpensesList.vue"),
        meta: { permissions: ["subcontractors-invoices-show"] }
      },
      {
        path: "add",
        name: "add-subcontractor-expenses",
        component: () => import("@/modules/subcontractor-expenses/pages/SubcontractorExpensesAdd.vue"),
        meta: { permissions: ["subcontractors-invoices-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-subcontractor-expenses",
        component: () => import("@/modules/subcontractor-expenses/pages/SubcontractorExpensesEdit.vue"),
        meta: { permissions: ["subcontractors-invoices-update"] }
      }
    ]
  }
];
