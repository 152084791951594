export default [
  {
    path: "/staff",
    name: "staff",
    component: () => import("@/modules/staff/Staff.vue"),
    children: [
      {
        path: "list",
        name: "list-staff",
        component: () => import("@/modules/staff/pages/StaffList.vue"),
        meta: { permissions: ["staff-show"] }
      },
      {
        path: "add",
        name: "add-staff",
        component: () => import("@/modules/staff/pages/StaffAdd.vue"),
        meta: { permissions: ["staff-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-staff",
        component: () => import("@/modules/staff/pages/StaffEdit.vue"),
        meta: { permissions: ["staff-update"] }
      }
    ]
  }
];
