import ApiService from "@/core/services/api.service";

//Actions
export const FETCH_NO_BOL = "fetchNoBol";
export const GENERATE_GPS_NO_BOL_EXCEL_URL = "generateGpsNoBolExcelUrl";

const state = {};

const getters = {};

const actions = {
  [FETCH_NO_BOL](context, apiParams = "") {
    return new Promise((resolve, reject) => {
      ApiService.get("gps-log/no-bol" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [GENERATE_GPS_NO_BOL_EXCEL_URL](context, params) {
    // context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get(`gps-log/generate-gps-no-bol-excel-url${params}`)
        .then(({ data }) => {
          window.open(data.data.url, "_blank");
          // resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          // context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};
