export default [
  {
    path: "/rented-machines",
    name: "rented-machines",
    component: () => import("@/modules/rented-machines/Rented-machines.vue"),
    children: [
      {
        path: "list",
        name: "list-rented-machines",
        component: () => import("@/modules/rented-machines/pages/RentedMachineList.vue"),
        meta: { permissions: ["rented-machines-show"] }
      },
      {
        path: "add",
        name: "add-rented-machine",
        component: () => import("@/modules/rented-machines/pages/RentedMachineAdd.vue"),
        meta: { permissions: ["rented-machines-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-rented-machine",
        component: () => import("@/modules/rented-machines/pages/RentedMachineEdit.vue"),
        meta: { permissions: ["rented-machines-update"] }
      }
    ]
  }
];
