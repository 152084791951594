export default [
  {
    path: "/statistics",
    name: "statistics",
    component: () => import("@/modules/statistics/Statistic.vue"),
    children: [
      {
        path: "list",
        name: "list-statistics",
        component: () => import("@/modules/statistics/pages/Statistics.vue"),
        meta: { permissions: ["statistics-show"] },
      },
    ],
  },
];
