export default [
  {
    path: "service-invoices/:invoiceId/service-invoice-rows",
    name: "service-invoice-rows",
    component: () =>
      import("@/modules/service-invoice-rows/ServiceInvoiceRows.vue"),
    children: [
      {
        path: "add",
        name: "add-service-invoice-rows",
        component: () =>
          import(
            "@/modules/service-invoice-rows/pages/ServiceInvoiceRowsAdd.vue"
          ),
        meta: { permissions: ["service-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-service-invoice-rows",
        component: () =>
          import(
            "@/modules/service-invoice-rows/pages/ServiceInvoiceRowsEdit.vue"
          ),
        meta: { permissions: ["service-update"] }
      }
    ]
  }
];
