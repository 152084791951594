export default [
  {
    path: "/service-invoices",
    name: "service-invoices",
    component: () => import("@/modules/service-invoices/ServiceInvoices.vue"),
    children: [
      {
        path: "list",
        name: "list-service-invoices",
        component: () =>
          import("@/modules/service-invoices/pages/ServiceInvoicesList.vue"),
        meta: { permissions: ["service-show"] }
      },
      {
        path: "add",
        name: "add-service-invoices",
        component: () =>
          import("@/modules/service-invoices/pages/ServiceInvoicesAdd.vue"),
        meta: { permissions: ["service-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-service-invoices",
        component: () =>
          import("@/modules/service-invoices/pages/ServiceInvoicesEdit.vue"),
        meta: { permissions: ["service-update"] }
      }
    ]
  }
];
