import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_SERVICE_WAREHOUSE_ITEMS = "fetchServiceWarehouseItems";
export const FETCH_SERVICE_WAREHOUSE_ITEM = "fetchServiceWarehouseItem";
export const SAVE_SERVICE_WAREHOUSE_ITEM = "saveServiceWarehouseItem";
export const UPDATE_SERVICE_WAREHOUSE_ITEM = "updateServiceWarehouseItem";
export const DELETE_SERVICE_WAREHOUSE_ITEM = "deleteServiceWarehouseItem";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingServiceWarehouseItem";

const state = {
  errors: [],
  isLoadingServiceWarehouseItem: false
};

const getters = {
  isLoadingServiceWarehouseItem() {
    return state.isLoadingServiceWarehouseItem;
  }
};

const actions = {
  [FETCH_SERVICE_WAREHOUSE_ITEMS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("service-warehouse-items" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_SERVICE_WAREHOUSE_ITEM](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("service-warehouse-items", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_SERVICE_WAREHOUSE_ITEM](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("service-warehouse-items", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_SERVICE_WAREHOUSE_ITEM](context, params) {
    context.commit(SET_LOADING, true);

    const id = params.id;

    return new Promise((resolve, reject) => {
      ApiService.update("service-warehouse-items", id, params.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_SERVICE_WAREHOUSE_ITEM](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("service-warehouse-items", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingServiceWarehouseItem = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingServiceWarehouseItem = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
