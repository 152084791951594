export default [
  {
    path: "service-machines/:machineId/service-machine-rows",
    name: "service-machine-rows",
    component: () =>
      import("@/modules/service-machine-rows/ServiceMachineRows.vue"),
    children: [
      {
        path: "add",
        name: "add-service-machine-rows",
        component: () =>
          import(
            "@/modules/service-machine-rows/pages/ServiceMachineRowsAdd.vue"
          ),
        meta: { permissions: ["service-create"] },
        props: true
      },
      {
        path: "edit/:id",
        name: "edit-service-machine-rows",
        component: () =>
          import(
            "@/modules/service-machine-rows/pages/ServiceMachineRowsEdit.vue"
          ),
        meta: { permissions: ["service-update"] }
      }
    ]
  }
];
