import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_TANKS = "fetchTanks";
export const FETCH_TANK = "fetchTank";
export const SAVE_TANK = "saveTank";
export const UPDATE_TANK = "updateTank";
export const DELETE_TANK = "deleteTank";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingTank";
export const SET_TANK = "setTank";

const state = {
  errors: [],
  tank: [],
  isLoadingTank: false
};

const getters = {
  isLoadingTank() {
    return state.isLoadingTank;
  },
  getTank() {
    return state.tank;
  }
};

const actions = {
  [FETCH_TANKS](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("tanks" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_TANK](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("tanks", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_TANK](context, payload) {
    context.commit(SET_LOADING, true);
    const form = payload;
    return new Promise((resolve, reject) => {
      ApiService.post("tanks", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_TANK](context, params) {
    context.commit(SET_LOADING, true);
    const form = params.payload;
    const id = params.id;
    return new Promise((resolve, reject) => {
      ApiService.update("tanks", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_TANK](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("tanks", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingTank = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingTank = value;
  },
  [SET_TANK](state, data) {
    state.isLoadingTank = false;
    state.tank = data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
