export default [
  {
    path: "/measures",
    name: "measures",
    component: () => import("@/modules/measures/Measures.vue"),
    children: [
      {
        path: "list",
        name: "list-measures",
        component: () => import("@/modules/measures/pages/MeasuresList.vue"),
        meta: { permissions: ["measures-show"] }
      },
      {
        path: "add",
        name: "add-measures",
        component: () => import("@/modules/measures/pages/MeasuresAdd.vue"),
        meta: { permissions: ["measures-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-measures",
        component: () => import("@/modules/measures/pages/MeasuresEdit.vue"),
        meta: { permissions: ["measures-update"] }
      }
    ]
  }
];
