import _ from "lodash";
const avatarService = {
  avatarColors: [
    "#A133BA",
    "#33B2E0",
    "#FFAD33",
    "#64E285",
    "#FFE66A",
    "#F37CC0",
    "#C2E570",
    "#D86363",
    "#B27FBE",
    "#999999"
  ],
  getInitials(value) {
    var names = value.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  },
  getInitialsColor(value){
    let initials = this.getInitials(value);
    let initialsColor = this.setBackgroundColor(initials);
    return initialsColor;
  },
  setBackgroundColor(text) {
    let selectedBgColor = "";
    if (text === "") {
      // if the contact is unknown, the color is random
      selectedBgColor = this.avatarColors[Math.floor(Math.random() * 10)];
      return;
    }
    let sum = 0;
    // get the unicode of the each char in the initials
    // and add its %10 to index
    for (let i = 0; i < text.length; i++) {
      sum += text.charCodeAt(i);
    }
    let index = sum % 10;
    selectedBgColor = this.avatarColors[index];
    return selectedBgColor;
  }
};

export default avatarService;
