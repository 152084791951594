export default [
  {
    path: "/machine-type",
    name: "machine-type",
    component: () => import("@/modules/machines-types/MachineType.vue"),
    children: [
      {
        path: "list",
        name: "list-machine-type",
        component: () => import("@/modules/machines-types/pages/MachineTypeList.vue"),
        meta: { permissions: ["machines-types-show"] }
      },
      {
        path: "add",
        name: "add-machine-type",
        component: () => import("@/modules/machines-types/pages/MachineTypeAdd.vue"),
        meta: { permissions: ["machines-types-create"] }
      },
      {
        path: "edit/:id",
        name: "edit-machine-type",
        component: () => import("@/modules/machines-types/pages/MachineTypeEdit.vue"),
        meta: { permissions: ["machines-types-update"] }
      }
    ]
  }
];
