import ApiService from "@/core/services/api.service";
// import JwtService from "@/core/services/jwt.service";

//Actions
export const FETCH_TANK_CHARGES = "fetchTankCharges";
export const FETCH_TANK_CHARGE = "fetchTankCharge";
export const SAVE_TANK_CHARGE = "saveTankCharge";
export const UPDATE_TANK_CHARGE = "updateTankCharge";
export const DELETE_TANK_CHARGE = "deleteTankCharge";
export const FETCH_MACHINE_AND_TANK_OPTIONS = "getMachineAndTankOptions";
export const SEARCH_TANK_CHARGES = "searchTankCharges";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingStaffVehicle";
export const SET_TANK_CHARGE = "setTankCharge";

const state = {
  errors: [],
  tank_charge: [],
  isLoadingTankCharge: false,
};

const getters = {
  isLoadingTankCharge() {
    return state.isLoadingTankCharge;
  },
  getTankCharge() {
    return state.tank_charge;
  },
};

const actions = {
  [FETCH_TANK_CHARGES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("tank-charges" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_TANK_CHARGE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("tank-charges", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_TANK_CHARGE](context, payload) {
    context.commit(SET_LOADING, true);
    let form = JSON.parse(JSON.stringify(payload));

    form.staffId = form.staffId < 0 ? null : form.staffId;
    
    return new Promise((resolve, reject) => {
      ApiService.post("tank-charges", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_TANK_CHARGE](context, params) {
    context.commit(SET_LOADING, true);

    let form = JSON.parse(JSON.stringify(params.payload));
    const id = params.id;

    form.staffId = form.staffId < 0 ? null : form.staffId;

    return new Promise((resolve, reject) => {
      ApiService.update("tank-charges", id, form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_MACHINE_AND_TANK_OPTIONS](context) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.get("machine-and-tank-options")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_TANK_CHARGE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("tank-charges", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SEARCH_TANK_CHARGES](context, apiParams = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("find-tank-charges" + apiParams)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, errors) {
    state.isLoadingTankCharge = false;
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_LOADING](state, value) {
    state.isLoadingTankCharge = value;
  },
  [SET_TANK_CHARGE](state, data) {
    state.isLoadingTankCharge = false;
    state.tank_charge = data.data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
